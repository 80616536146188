import request from '@/utils/request'

// 登录
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

export function getInfo(token) {
  return request({
    url: '/user/info',
    method: 'get',
    params: {
      token
    }
  })
}
//POST / changePwd
export function changePwd(data) {
  return request({
    url: '/changePwd',
    method: 'post',
    data
  })
}
export function logout() {
  return request({
    url: '/loginOut',
    method: 'post'
  })
}

//GET /getDataScopeList

export function getDataScopeList(data) {
  return request({
    url: '/getDataScopeList',
    method: 'get',
    params: {
      data
    }
  })
}

export function getUserMenu(token) {
  return request({
    url: '/menu/getUserMenu',
    method: 'get',
    params: {
      token
    }
  })
}