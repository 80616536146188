import request from '@/utils/request'
// GPOST /role/addRole
export function addRole(data) {
    return request({
        url: '/role/addRole',
        method: 'post',
        data
    })
}
// DELETE /role/delRole
export function delRole(data) {
    return request({
        url: '/role/delRole',
        method: 'delete',
        params: data
    })
}

//POST /role/editRole
export function editRole(data) {
    return request({
        url: '/role/editRole',
        method: 'post',
        data
    })
}

//GET /role/getRoleList
export function getRoleList(data) {
    return request({
        url: '/role/getRoleList',
        method: 'get',
        params: data
    })
}
//POST /role/setRoleMenu
export function setRoleMenu(data) {
    return request({
        url: '/role/setRoleMenu',
        method: 'post',
        data
    })
}

//menu/getUserMenu
export function getUserMenu(data) {
    return request({
        url: '/menu/getUserMenu',
        method: 'get',
        params: data
    })
}

//878/role/getRoleList
export function getRoleSlect(data) {
    return request({
        url: '/role/getRoleSelect',
        method: 'get',
        params: data
    })
}