import request from '@/utils/request'
// POST /sysUser/add
export function add(data) {
    return request({
        url: '/sysUser/add',
        method: 'post',
        data

    })
}
// POST /sysUser/getUserList
export function getUserList(data) {
    return request({
        url: '/sysUser/getUserList',
        method: 'post',
        params: data

    })
}
// POST /sysUser/setUserRole
export function setUserRole(data) {
    return request({
        url: '/sysUser/setUserRole',
        method: 'post',
        data

    })
}
// POST /sysUser/resetPassword
export function resetPassword(data) {
    return request({
        url: '/sysUser/resetPassword',
        method: 'post',
        params:data

    })
}
// POST /sysUser/updateUser
export function updateUser(data) {
    return request({
        url: '/sysUser/updateUser',
        method: 'post',
        data

    })
}

// DELETE /sysUser/delUser

export function delUser(data) {
    return request({
        url: '/sysUser/delUser',
        method: 'delete',
        params: data

    })
}