import request from '@/utils/request'
import qs from 'qs'
import { getToken,getName } from '@/utils/auth'
//Statistics/getUsersAndSales
export function getUsersAndSales(data) {
    return request({
        url: '/Statistics/getUsersAndSales',
        method: 'get',
        params: data

    })
}

// Statistics/getSalesDetail
export function getSalesDetail(data) {
    return request({
        url: '/Statistics/getSalesDetail',
        method: 'get',
        params: data

    })
}

///Statistics/getSalesData
export function getSalesData(data) {
    return request({
        url: '/Statistics/getSalesData',
        method: 'get',
        params: data

    })
}

///Statistics/getNewUserCount
export function getNewUserCount(data) {
    return request({
        url: '/Statistics/getNewUserCount',
        method: 'get',
        params: data

    })
}

// Statistics/getPilesInfo
export function getPilesInfo(data) {
    return request({
        url: '/Statistics/getPilesInfo',
        method: 'get',
        params: data
    })
}

// Statistics/getRechargeDetail
export function getRechargeDetail(data) {
    return request({
        url: '/Statistics/getRechargeDetail',
        method: 'get',
        params: data
    })
}

// Statistics/getDayRecharge
export function getDayRecharge(data) {
    return request({
        url: '/Statistics/getDayRecharge',
        method: 'get',
        params: data
    })
}
// 导出excel
export function exportExcel(query) {
    return request({
        responseType: 'blob',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded','token': getToken() },
        transformRequest: [(params) => { return qs.stringify(params, { indices: false }) }],
        url: '/Statistics/export',
        method: 'get',
        params: query
    })
}

export function getManageTotalData(data) {
    return request({
        url: '/Statistics/getManageTotalData',
        method: 'post',
        data: data

    })
}

// Lyle
//数据概览
export function getManageOverview(data) {
    console.log('data',data)
    return request({
        url: 'index/statistics/overview',
        method: 'get',
        params: data

    })
}

//经营趋势
export function getManageTrendRevenue(data) {
    return request({
        url: 'index/statistics/trendRevenue',
        method: 'get',
        params: data

    })
}

//充电时段分布
export function getManageChargeTime(data) {
    return request({
        url: 'index/statistics/chargeTime',
        method: 'get',
        params: data

    })
}

//充电枪状态
export function getManageGunStatus(data) {
    return request({
        url: 'index/statistics/gunStatus',
        method: 'get',
        params: data

    })
}

//站点功率利用率排行
export function getManagePowerUtilization(data) {
    return request({
        url: 'index/statistics/powerUtilization',
        method: 'get',
        params: data

    })
}