import request from '@/utils/request'
//GET /sysRule/getRuleList
export function getRuleList(data) {
    return request({
        url: '/sysRule/getRuleList',
        method: 'get',
        params: data

    })
}

//POST /sysRule/addRule
export function addRule(data) {
    return request({
        url: '/sysRule/v2/addRule',
        method: 'post',
        data

    })
}
//sysRule/deleteRule
export function deleteRule(data) {
    return request({
        url: '/sysRule/deleteRule',
        method: 'delete',
        params: data
    })
}
///sysRule/addCustomPrice'
export function addCustomPrice(data) {
    return request({
        url: '/sysRule/addCustomPrice',
        method: 'post',
        data
    })
}

//sysRule/editCustomPrice'

export function editCustomPrice(data) {
    return request({
        url: '/sysRule/editCustomPrice',
        method: 'post',
        data
    })
}
export function delCustomPrice(data) {
    return request({
        url: '/sysRule/delCustomPrice',
        method: 'delete',
        params: data
    })
}
//sysRule/editeRule
export function editRule(data) {
    return request({
        url: '/sysRule/v2/editRule',
        method: 'post',
        data

    })
}
export function copyRule(data){
    return request({
        url: '/sysRule/copyRule',
        method: 'get',
        params: data
    })
}
//sysRule/getCustomPrice
export function getCustomPrice(data) {
    return request({
        url: '/sysRule/getCustomPrice',
        method: 'get',
        params: data
    })
}

//sysRule/getRule'
export function getRule(data) {
    return request({
        url: '/sysRule/getRule',
        method: 'get',
        params: data
    })
}

//sysRule/getRulePriceList
export function getRulePriceList(data) {
    return request({
        url: '/sysRule/getRulePriceList',
        method: 'get',
        params: data
    })
}